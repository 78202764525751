import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from './Carousel.module.scss';
import images from '../../constants/imagesHomePage';
import { preventDefault } from '../../utils/preventDefault';

const CarouselSlide = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef(0);
  const [isTouchEnabled, setIsTouchEnabled] = useState(false);

  const slides = [
    {
      title: 'Đội ngũ chuyên gia với kinh nghiệm dày dặn',
      description: 'Arisen tự hào có chuyên gia công nghệ giàu kinh nghiệm, đồng hành giúp tối ưu tiềm năng doanh nghiệp.',
      icon: images.hexagon,
    },
    {
      title: 'Sự cam kết đồng hành và hỗ trợ toàn diện',
      description: 'Arisen luôn đồng hành cùng bạn từ bước lập kế hoạch, triển khai đến bảo trì giải pháp toàn diện.',
      icon: images.gear,
    },
    {
      title: 'Giải pháp chuyển đổi số tích hợp',
      description: 'Arisen tối ưu quy trình và tăng hiệu suất giúp doanh nghiệp phát triển mạnh mẽ trong kỷ nguyên số.',
      icon: images.bulb,
    }
  ];

  const scrollToCard = useCallback(
    (cardIndex: number) => {
      setCurrentCard(cardIndex);
    },
    []
  );

  // Check screen width
  const checkScreenWidth = useCallback(() => {
    setIsTouchEnabled(window.innerWidth <= 480);
  }, []);

  useEffect(() => {
    // Check screen size on initial render
    checkScreenWidth();

    // Listen for window resize events
    window.addEventListener('resize', checkScreenWidth);
    
    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, [checkScreenWidth]);

  const handleTouchStart = (e: React.TouchEvent) => {
    if (!isTouchEnabled) return;  // Ignore if touch events are disabled
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (!isTouchEnabled) return;  // Ignore if touch events are disabled
    const touchEndX = e.changedTouches[0].clientX;
    const diffX = touchStartX.current - touchEndX;

    // Check swipe distance to determine the direction
    if (diffX > 50) goToNextCard();  // Swipe left
    else if (diffX < -50) goToPrevCard();  // Swipe right
  };

  const goToNextCard = () => {
    setCurrentCard((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToPrevCard = () => {
    setCurrentCard((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  return (
    <div
      className={styles.container}
      ref={cardsContainerRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className={styles.slideContainer}
        style={{
          transform: `translateX(-${currentCard * 100}%)`,
          transition: 'transform 0.5s ease',
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`${styles.slide} ${index === currentCard ? styles.active : ''}`}
          >
            <div className={styles.textSection}>
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
            </div>
            <div className={styles.iconSection}>
              <img src={slide.icon} alt={`${slide.title} Icon`} className={styles.icon} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.indicators} onClick={preventDefault}>
        {slides.map((_, index) => (
          <span
            key={index}
            onClick={() => scrollToCard(index)}

            className={index === currentCard ? styles.activeDot : styles.dot}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default CarouselSlide;
