import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import styling from './servicePageStyling.module.scss'
import imgService from './imgServicePage'
import Header from '../pages/LayoutPage/Header';
import { EHeaderTab } from '../types/pageLayout';
import { navigateHeader } from '../utils/navigateHeader';
import useLoading from '../hooks/useLoading';
import { useAppDispatch } from '../app/hooks';
import { setPreviousPath } from '../redux/loadingSlice';

const ServicePage = () => {
    useLoading();

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();


    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    useEffect(() => {
        window.onpopstate = (e) => {
            const previousPath = location.pathname + location.hash;
            dispatch(setPreviousPath(previousPath));
        };
    });

    return (
        <div className={styling.body}>
            <Header activeTab={EHeaderTab.SERVICE} onClick={(tab: EHeaderTab) => navigateHeader(tab, navigate)} />
            <div className={styling.container}>
                <div className={styling.header}>
                    <h1>Dịch Vụ</h1>
                </div>
            </div>

            <div className={styling.website} id="website-section">
                <div className={styling.title}>
                    <div className={styling.head}>
                        <h2>Xây dựng <br /> website</h2>
                    </div>

                    <div className={styling.dn}>
                        <p>Chúng tôi giúp trang web của bạn kể câu chuyện doanh nghiệp theo cách riêng</p>
                        <button className={styling.WebsiteTablet}>→</button>
                    </div>
                </div>

                <div className={styling.description}>
                    <div className={styling.col_1}>
                        <img className={styling.desktop} src={imgService.Website} alt='' />
                    </div>

                    <div className={styling.col_2}>
                        <div className={styling.oop}>
                            <img className={styling.xicon} src={imgService.iconx} alt="" />
                            <p>Nội dung chuyên biệt hóa theo đối tượng</p>
                        </div>

                        <div className={styling.design}>
                            <img className={styling.speedy} src={imgService.speed} alt="" />
                            <p>Cấu trúc dễ mở rộng, tiết kiệm thời gian & chi phí</p>
                        </div>

                        <div className={styling.authorize}>
                            <img className={styling.key} src={imgService.token} alt="" />
                            <p>Tích hợp API nâng cao tương tác</p>
                        </div>

                        <div className={styling.update}>
                            <img className={styling.srch} src={imgService.search} alt="" />
                            <p>Cập nhật liên tục & cải thiện thứ hạng tìm kiếm</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styling.mobileDesign} id="mobile-section">
                <div className={styling.heading}>
                    <h2 className={styling.head}>Phát triển <br className={styling.line} /> ứng dụng di động</h2>

                    <div className={styling.subtext}>
                        <p className={styling.cap}>Chúng tôi kết nối doanh nghiệp với khách hàng qua ứng dụng di động, cam kết mang lại trải nghiệm vượt trội.</p>
                        <button className={styling.MobileDesginTablet}>→</button>
                    </div>
                </div>

                <div className={styling.content}>
                    <div className={styling.col1}>
                        <div className={styling.xdesign}>
                            <img className={styling.designX} src={imgService.iconx} alt="" />
                            <p>Thiết kế hiện đại và phù hợp với thương hiệu</p>
                        </div>

                        <div className={styling.Webhook}>
                            <img className={styling.WebHook} src={imgService.webHook} alt="" />
                            <p>Trải nghiệm người dùng tối ưu</p>
                        </div>

                        <div className={styling.token2}>
                            <img className={styling.Token2} src={imgService.token} alt="" />
                            <p>Kết nối và tương tác tức thời</p>
                        </div>

                        <div className={styling.ReloadFrame}>
                            <img className={styling.FrameReload} src={imgService.frameReload} alt="" />
                            <p>Tích hợp tính năng độc quyền</p>
                        </div>
                    </div>

                    <div className={styling.col2}>
                        <img className={styling.mobileIcon} src={imgService.mobile} alt="" />
                    </div>
                </div>
            </div>

            <div className={styling.iotInjection} id="iot-section">
                <div className={styling.caption}>
                    <h2 className={styling.header}>Phát triển phần mềm nhúng IoT</h2>

                    <div className={styling.textsub}>
                        <p className={styling.textDescription}>Chúng tôi cung cấp giải pháp phần mềm nhúng IoT với bảo mật cao và tối ưu hiệu suất.</p>
                        <button className={styling.iotInjectionTablet}>→</button>
                    </div>
                </div>

                <div className={styling.Content}>
                    <div className={styling.Col1}>
                        <img className={styling.iot} src={imgService.IoT} alt="" />
                    </div>

                    <div className={styling.Col2}>
                        <div className={styling.security}>
                            <img className={styling.safety} src={imgService.iconx} alt="" />
                            <p>Bảo mật dữ liệu & thiết bị toàn diện</p>
                        </div>

                        <div className={styling.performance}>
                            <img className={styling.quality} src={imgService.speed} alt="" />
                            <p>Đạt hiệu suất tối ưu</p>
                        </div>

                        <div className={styling.key2}>
                            <img className={styling.RSAkey} src={imgService.token} alt="" />
                            <p>Dễ dàng kết nối với các thiết bị khác</p>
                        </div>

                        <div className={styling.Support}>
                            <img className={styling.supportTeam} src={imgService.search} alt="" />
                            <p>Đội ngũ hỗ trợ 24/7</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicePage