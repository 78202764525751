import logo1 from '../assets/images/homePageImages/homeLogos/phanLeLogo.png';
import logo2 from '../assets/images/homePageImages/homeLogos/FuturegenLogo.png';
import logo3 from '../assets/images/homePageImages/homeLogos/geltechLogo.png';
import logo4 from '../assets/images/homePageImages/homeLogos/greenleaflogo.png';
import logo5 from '../assets/images/homePageImages/homeLogos/greenSolutionLogo.png';
import logo6 from '../assets/images/homePageImages/homeLogos/nextGenlogo.png';
import logo7 from '../assets/images/homePageImages/homeLogos/ecolinkLogo.png';
import logo8 from '../assets/images/homePageImages/homeLogos/resLogo.png';
import logo9 from '../assets/images/homePageImages/homeLogos/smartCraftLogo.png';

const logosHomePage = {
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
};

export default logosHomePage;