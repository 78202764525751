import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoadingState {
    isLoading: boolean;
    previousPath: string | null;
}

const initialState: LoadingState = {
    isLoading: false,
    previousPath: null,	
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setPreviousPath(state, action: PayloadAction<string | null>) {
            state.previousPath = action.payload;
        },
    },
});

export const { setLoading, setPreviousPath } = loadingSlice.actions;
