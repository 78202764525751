import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from "./HomePage.module.scss";
import Button from "../components/Buttons/Buttons";
import ServiceCard from '../components/ServiceCard/ServiceCard';
import CarouselSlide from '../components/Carousel/Carousel';
import images from '../constants/imagesHomePage';
import logos from '../constants/imagesHomeLogo';
import Header from '../pages/LayoutPage/Header';
import { EHeaderTab } from '../types/pageLayout';
import { navigateHeader } from '../utils/navigateHeader';
import useLoading from '../hooks/useLoading';
import { preventDefault } from '../utils/preventDefault';
import logosHomePage from '../constants/imagesHomeLogo';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setPreviousPath } from '../redux/loadingSlice';

const HomePage: React.FC = () => {
  useLoading();

  const navigate = useNavigate(); 
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [backgroundImage, setBackgroundImage] = useState(images.planet);

  const previousPath = useAppSelector((state) => state.loading.previousPath);

  useEffect(() => {
    const updateImage = () => {
      setBackgroundImage(window.innerWidth <= 480 ? images.mobilePlanet : images.planet);
    };

    updateImage();
    window.addEventListener('resize', updateImage);

    return () => window.removeEventListener('resize', updateImage);
  }, []);

  useEffect(() => {
    window.onpopstate = (e) => {
        console.log(location.pathname);
        // dispatch(setPreviousPath(location.pathname));
    };
});

  useEffect(() => {
    if (!previousPath) return;
    let element = null;
    switch (previousPath) {
      case '/service#website-section':
        element = document.getElementById('homepage-website-section');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/service#mobile-section':
        element = document.getElementById('homepage-mobile-section');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/service#iot-section':
        element = document.getElementById('homepage-iot-section');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      default:
        break;
    }
    dispatch(setPreviousPath(null));
  }, [dispatch, previousPath]);

  const numberOfLogos = Object.keys(logosHomePage).length;
    
  return (
    <body className={styles.body}>
      <Header activeTab={EHeaderTab.HOME} onClick={(tab: EHeaderTab) => navigateHeader(tab, navigate)} />
      <section className={styles.heroContent}>
        <div className={styles.heroText}>
          <h1 className={styles.heroTitle}>Giải pháp phần mềm cho doanh nghiệp vừa và nhỏ</h1>
          <p className={styles.description}>
            Arisen giúp doanh nghiệp chuyển đổi số toàn diện với các giải pháp
            phần mềm chuyên biệt, tối ưu quy trình và nâng cao trải nghiệm.
          </p>
        </div>

        <div className={styles.buttonContainer}>
          <Link to="/contact">
            <Button variant="orange" text="Nhận tư vấn miễn phí" />
          </Link>              
          <a href="tel:+84888440295">
            <Button variant="black" text="Hotline: 0888440295" />
          </a>            
        </div>
        
        <div className={styles.partners}>
          <div className={styles.backgroundContainer}>
            {/* Apply preventDefault to background image */}
            <img src={backgroundImage} alt="Background" className={styles.backgroundImage} onClick={preventDefault} />
            
            <div className={styles.overlayText}>
              Được tin tưởng bởi hơn {numberOfLogos} đối tác
            </div>

            <div className={styles.brandLogos}>
              {/* Apply preventDefault to brand logos image */}
              <img src={logos.logo1} alt="Brand Logos" className={styles.brandImage} onClick={preventDefault} />
              <img src={logos.logo4} alt="Brand Logos" className={styles.brandsImage} onClick={preventDefault} />
              <img src={logos.logo3} alt="Brand Logos" className={styles.brandsImage} onClick={preventDefault} />
              <img src={logos.logo5} alt="Brand Logos" className={styles.brandsImage} onClick={preventDefault} />
              <img src={logos.logo6} alt="Brand Logos" className={styles.brandsImage} onClick={preventDefault} />
              <img src={logos.logo2} alt="Brand Logos" className={styles.brandsImage} onClick={preventDefault} />
              <img src={logos.logo8} alt="Brand Logos" className={styles.brandsImage} onClick={preventDefault} />
              <img src={logos.logo7} alt="Brand Logos" className={styles.brandsImage} onClick={preventDefault} />
              <img src={logos.logo9} alt="Brand Logos" className={styles.brandsImage} onClick={preventDefault} />
              
            </div>
          </div>
        </div>
      </section>
      
      <section className={styles.servicesSection}>
        <ServiceCard />
      </section>
      
      <section className={styles.carouselSection}>
        <h2 className={styles.carouselTitle}>
          Tại sao Arisen là lựa chọn <span className={styles.numberOne}>số 1?</span>
        </h2>          
        <CarouselSlide />
      </section>
    </body>
  );
};

export default HomePage;
