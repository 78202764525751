import React, { useEffect, useRef, useState } from 'react'
import styles from './Footer.module.scss'
import Button from '../../components/Footer/ButtonsFooter'
import imagesFooter from '../../constants/imagesFooter'
import { useLocation, useNavigate } from 'react-router-dom'
import { EHeaderTab } from '../../types/pageLayout'
import { navigateHeader } from '../../utils/navigateHeader'

const Footer = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const [showBeginSection, setShowBeginSection] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            if (location.pathname === '/contact') {
                setShowBeginSection(window.innerWidth <= 480);
            } else {
                setShowBeginSection(true);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location]);

    const footerClasses = `${styles.container} ${location.pathname === '/contact' ? styles.contactPage : ''}`;

    const onNavigate = (tab: EHeaderTab) => {
        navigateHeader(tab, navigate);
    };

    return (
        <div ref={containerRef} className={footerClasses}>
            {showBeginSection && (
                <div className={styles.begin}>
                    <div className={styles.beginContainer}>
                        <div className={styles.head}>
                            <h1>Bắt đầu hành trình chuyển đổi số ngay hôm nay!</h1>
                        </div>

                        <div className={styles.subtext}>
                            <div className={styles.ButtonContainer}>
                                <div className={styles.button1} onClick={() => onNavigate(EHeaderTab.CONTACT)}>
                                    <Button variant="orange" text="Liên hệ tư vấn" />
                                </div>

                                <a className={styles.button2} href="tel:+84888440295">
                                    <Button variant="black" text="Hotline: 0888440295" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.foot}>
                <div className={styles.col1}>
                    <div className={styles.Col1}>
                        <img src={imagesFooter.logo} alt="logo" className={styles.logo} />
                    </div>
                    <div className={styles.Col2}>
                        <div className={styles.Address}>
                            <p className={styles.address}>Địa chỉ: Tầng 4, TTTM V+, Số 505 Phố Minh Khai, Phường Vĩnh Tuy, Quận Hai Bà Trưng, Thành phố Hà Nội, Việt Nam</p>
                        </div>

                        <div className={styles.Phone}>
                            <p className={styles.phone}>Hotline: 0888440295</p>
                        </div>

                        <div className={styles.Mail}>
                            <p className={styles.mail}>Email: arisenjsc@gmail.com</p>
                        </div>
                    </div>
                </div>

                <div className={styles.col2}>
                    <div className={styles.Col2_1}>
                        <div className={styles.home} onClick={() => onNavigate(EHeaderTab.HOME)}>
                            <p className={styles.Home}>Trang chủ</p>
                        </div>
                        <div className={styles.service} onClick={() => onNavigate(EHeaderTab.SERVICE)}>
                            <p className={styles.Service}>Dịch vụ</p>
                        </div>
                        <div className={styles.about} onClick={() => onNavigate(EHeaderTab.ABOUT)}>
                            <p className={styles.About}>Về chúng tôi</p>
                        </div>
                        <div className={styles.contact} onClick={() => onNavigate(EHeaderTab.CONTACT)}>
                            <p className={styles.Contact}>Liên hệ</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;